import {BaseService} from "@/core/services/BaseService";

class GroupService extends BaseService<any> {
  protected endpoint = '/group'

  async addUser(teamId, model) {
    return await this.http().put(this.endpoint + "/" + teamId + "/users", model).then(res => {
      return res.data
    })
  }

  async getUsers(groupId) {
    return await this.http().get(this.endpoint + "/" + groupId + "/users").then(res => {
      return res.data
    })
  }

  async remove(id, userId) {
    return await this.http().delete(this.endpoint + "/" + id + "/users/" + userId).then(res => {
      return res.data
    })
  }

  async addGroup(groupId, userGroupId) {
    return await this.http().put(this.endpoint + "/" + groupId + "/user-group/" + userGroupId).then(res => {
      return res.data
    })
  }

  async removeGroup(groupId, userGroupId) {
    return await this.http().delete(this.endpoint + "/" + groupId + "/user-group/" + userGroupId).then(res => {
      return res.data
    })
  }


  async switchEntity(id, type) {
    return await this.http().put(this.endpoint + "/" + id + "/switch/" + type).then(res => {
      return res.data
    })
  }

  async getMetricGroup(id) {
    return await this.http().get(this.endpoint + "/" + id + "/metric-groups").then(res => {
      return res.data
    })
  }

  async getCompanies() {
    return await this.http().get(this.endpoint + "/companies").then(res => {
      return res.data
    })
  }

  async getTeams(isCompany: boolean = false) {
    return await this.http().get(this.endpoint + "/teams?isCompany=" + isCompany).then(res => {
      return res.data
    })
  }

  async getWorkspaces() {
    return await this.http().get(this.endpoint + "/workspaces").then(res => {
      return res.data
    })
  }

  async getDashboards() {
    return await this.http().get(this.endpoint + "/dashboards").then(res => {
      return res.data
    })
  }

  async getCompanyInfo(token: string) {
    return await this.http().get(this.endpoint + "/company/info/" + token).then(res => {
      return res.data
    })
  }
}

export default new GroupService();