
import {defineComponent, ref, watch} from "vue";
import GroupService from "@/core/services/GroupService";
import BaseButton from "@/components/base/form/BaseButton.vue";
import UserService from "@/core/services/UserService";

export default defineComponent({
  name: "Invite",
  components: {BaseButton},
  props: {
    token: {type: String},
  },
  setup(props) {
    const info = ref<any>({});
    const loadInfo = (token) => {
      GroupService.getCompanyInfo(token).then(res => {
        info.value = res;
      })
    }

    const registered = ref(false)
    loadInfo(props.token);
    watch(() => props.token, cb => {
      loadInfo(cb);
    })
    return {
      info,
      registered,
    }
  },
  methods: {
    formSubmit() {
      UserService.joinConfirm(this.token).then(() => {
        this.registered = true
      })
    }
  }
})
